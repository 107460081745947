import React, { Component } from 'react';
import './exchangeItem.css';
import usd from '../../static/img/usd.svg';
import chevronRight from '../../static/img/chevron-right.svg';
import { formatCountToString } from '../../utils/priceFunctions';
import { withTranslation } from 'react-i18next';

class ExchangeHistoryItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div onClick={this.props.openDetails} className='exchange-item'>
        <div className='exchange-item__left'>
          <div className='exchange-item__images'>
            <img src={this.props.input.image ? this.props.input.image : usd} alt='usdt'></img>
            <img src={this.props.output.image ? this.props.output.image : usd} alt='usd'></img>
          </div>
          <div className='exchange-item__info'>
            <p style={{ textTransform: "uppercase" }} className='exchange-item__text'>{this.props.input.name} → {this.props.output.name}</p>
            <p className='exchange-item__title'>{formatCountToString(this.props.input.count)}</p>
            <p className='exchange-item__text'>{this.props.date}</p>
          </div>
        </div>
        <div className='exchange-item__right'>
          <div className='exchange-item__info'>
            <p className='exchange-item__text'>{this.props.fee}% {this.props.t("fee")}</p>
            <p className='exchange-item__title'>{formatCountToString(this.props.output.count)}</p>
            <div className='exchange-item__status'>
              <div className={`circle-status ${this.props.status.toLowerCase()}`}></div>
              <p className='exchange-item__text'>{this.props.t(this.props.status.toLowerCase())}</p>
            </div>
          </div>
          <img src={chevronRight} alt='arrowRight'></img>
        </div>
      </div>
    )
  }
}


export default withTranslation()(ExchangeHistoryItem);
