import React, { Component } from 'react';
import '../../static/css/bootstrap-grid.css'
import '../../core/core.css';
import InputCode from '../../components/inputCode/InputCode';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator/PasswordStrengthIndicator';
import eyeIcon from '../../static/img/eye.svg';
import eyeSlashIcon from '../../static/img/eye-slash.svg';
import backIcon from '../../static/img/back.png';
import Loading from '../../components/loading/Loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ForgotPassPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            confPass: "",
            code: "",
            codeStatus: "",
            step: "email",
            isVisiblePassword: false,
            isVisibleRepeat: false,
            error: "",
            passwordStrength: 0,
            loadingEmail: false,
            loadingCode: false,
            resendCode: false
        }
    }

    handleNavigateBack = () => {
        this.props.history.goBack();
    };

    sendEmailCode = async () => {
        this.setState({ loadingEmail: true });

        const api_url = await fetch(`user/getCodeMail/0/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email
        });
        const data = await api_url.json();

        this.setState({ loadingEmail: false });

        if (data['result']) {
            this.setState({ step: "code", error: "" });
            return;
        }
        this.setState({ error: this.props.t('email_not_exits') });
    }

    accessCode = async () => {
        this.setState({ loadingCode: true })
        const api_url = await fetch(`user/ifCodeMail/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email + "&code=" + this.state.code
        });
        const data = await api_url.json();

        this.setState({ loadingCode: false, codeStatus: data['result'] })

        if (data['result']) {
            this.setState({ step: "newPass" });
        }
    }


    setNewPassword = async () => {
        if (this.state.password !== this.state.confPass) {
            this.setState({ error: this.props.t("passwrods_not_match") })
            return;
        }

        const api_url = await fetch(`user/setNewPassword/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email + "&code=" + this.state.code + "&password=" + this.state.password
        });
        const data = await api_url.json();
        console.log(data);

        if (data['result']) {
            this.props.IsLogin();
            this.props.history.push('/exchange');
        } else {
            this.setState({ error: this.props.t("password_same") });
        }
    }

    validateEmail = (str) => {
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/);

        return !emailRegex.test(str);
    }


    hasWhiteSpaces = () => {
        const password = this.state.password;

        return password.length > 0 && password.indexOf(' ') < 0;
    }

    hasEightCharacters = () => {
        const password = this.state.password;

        return password.length >= 8;
    }

    checkCaseCharacters = () => {
        const password = this.state.password;

        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);

        return hasUppercase && hasLowercase && hasNumber;
    }

    hasSpecialCharacters = () => {
        const password = this.state.password;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasSpecialChar;
    }

    isValidPassword = () => {
        const hasWhiteSpaces = this.hasWhiteSpaces() ? 1 : 0;
        const hasEightCharacters = this.hasEightCharacters() ? 1 : 0;
        const checkCaseCharacters = this.checkCaseCharacters() ? 1 : 0;
        const hasSpecialCharacters = this.hasSpecialCharacters() ? 1 : 0;

        const result = hasWhiteSpaces + hasEightCharacters + checkCaseCharacters + hasSpecialCharacters;

        return result;
    }

    componentDidMount() {
        if (this.props.User['auth']) {
            this.props.history.goBack();
        }
    }

    render() {
        if (this.state.step === "email") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <div>
                                <p onClick={this.handleNavigateBack}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} /> {this.props.t('back')}</p>
                                <h1>{this.props.t('forgot_password')}</h1>
                                <p>{this.props.t('forgot_password_desc1')}</p>

                                <div className='inputLogin' style={{ marginBottom: 35 }}>
                                    <p>{this.props.t('email')}</p>
                                    <input maxLength="30" type="email" placeholder={this.props.t('enter_email')} onChange={(evt) => { this.setState({ email: evt.target.value.toLowerCase(), error: "" }) }}></input>
                                    <span className='incorrect-code'>{this.state.error}</span>
                                </div>
                            </div>

                            {this.state.loadingEmail ? <div className='row justify-content-center'><Loading /></div> : <button disabled={this.validateEmail(this.state.email)} className='submitButtonLoginStyle' onClick={() => this.sendEmailCode()}>{this.props.t('next')}</button>}

                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.step === "code") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <p onClick={() => this.setState({ step: "email" })}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} /> {this.props.t('back')}</p>
                            <h1>{this.props.t('forgot_password')}</h1>
                            <p style={{ whiteSpace: "pre-line" }}>{this.props.t('forgot_password_desc2')} {this.state.email} {'\n'} {this.props.t('check_spam_folder')}</p>

                            <div className='inputLogin' style={{ marginBottom: 35 }}>
                                <p>{this.props.t('code_verify_email')}</p>
                                <InputCode codeStatus={this.state.codeStatus} code={this.state.code} setCode={(evt) => this.setState({ code: evt })} />
                                {this.state.codeStatus === false && <span className='incorrect-code'>Incorrect code</span>}
                                {(this.state.codeStatus !== true && this.state.loadingCode === false) && <div onClick={() => {
                                    this.sendEmailCode();
                                    this.setState({ resendCode: true, codeStatus: "" });
                                }} className='forgotPass__resend-code'>{this.props.t('resend_code')}</div>}
                                {this.state.resendCode === true && this.state.codeStatus === "" && <span className='code-sent'>Code sent succesfully</span>}
                            </div>

                            {this.state.loadingCode ? <div className='row justify-content-center'><Loading /></div> : <button disabled={this.state.code.length < 6} className='submitButtonLoginStyle' onClick={() => this.accessCode()}>{this.props.t('next')}</button>}

                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.step === "newPass") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <div>
                                <h1 style={{ margin: 0 }}>{this.props.t('set_new_password')}</h1>

                                <div className='inputLogin'>
                                    <p>{this.props.t('new_password')}</p>
                                    <div className='inputLogin__container'>
                                        <input maxLength="64" style={this.state.error.length > 0 ? {
                                            border: "1px solid #DE5656"
                                        } : {}} type={`${this.state.isVisiblePassword ? "text" : "password"}`} placeholder={this.props.t('enter_password')} onChange={(evt) => { this.setState({ password: evt.target.value, error: "" }) }}></input>
                                        <img onClick={() => this.setState({ isVisiblePassword: !this.state.isVisiblePassword })} src={this.state.isVisiblePassword ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                                    </div>
                                    {this.state.password.length > 0 && <PasswordStrengthIndicator score={this.isValidPassword()} password={this.state.password} />}
                                </div>

                                <div className='inputLogin'>
                                    <p>{this.props.t('confirm_new_password')}</p>
                                    <div className='inputLogin__container'>
                                        <input style={this.state.error.length > 0 ? {
                                            border: "1px solid #DE5656"
                                        } : {}} maxLength="64" type={`${this.state.isVisibleRepeat ? "text" : "password"}`} placeholder={this.props.t('enter_password')} onChange={(evt) => { this.setState({ confPass: evt.target.value, error: "" }) }}></input>
                                        <img onClick={() => this.setState({ isVisibleRepeat: !this.state.isVisibleRepeat })} src={this.state.isVisibleRepeat ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                                        <span className='incorrect-code'>{this.state.error}</span>
                                    </div>

                                </div>
                            </div>
                            <button disabled={this.isValidPassword() < 4} className='submitButtonLoginStyle' style={{ marginTop: "22px" }} onClick={() => this.setNewPassword()}>{this.props.t('set_password')}</button>

                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default withTranslation()(withRouter(ForgotPassPage));