import './inputWithLabel.css';

const InputWithLabel = ({ labelText, ...props }) => {
    return (
        <div className='input-with-label'>
            <label>{labelText ?? ""}</label>
            <input {...props}></input>

        </div>
    )
}

export default InputWithLabel