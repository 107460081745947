import { useEffect, useState } from 'react';
import './qrCodeModal.css';
import QROpenButton from './components/QROpenButton/QROpenButton';
import QRCode from "react-qr-code";
import crossIcon from '../../static/img/cross.svg';
import { BASE_URL } from '../../static/constants/urls';
import { useTranslation } from 'react-i18next';


const QRCodemodal = () => {
  const { t } = useTranslation();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [qrValue, setQrValue] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const getQRCode = async () => {
    const response = await fetch("user/getTokenQR/");
    const json = await response.json();

    const url = json.result ? `${BASE_URL}/?QR=${json.QR}` : `${BASE_URL}`;

    setQrValue(url);
  }

  useEffect(() => {
    if (isModalOpened) {
      getQRCode();
    } else {
      setQrValue(false);
    }
  }, [isModalOpened]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }

    const handleResize = () => {
      const width = window.innerWidth;

      if (width > 768) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])

  return (
    <>
      {isButtonVisible && <QROpenButton setIsModalOpened={setIsModalOpened} />}
      {isModalOpened && <div className='qr-code-modal__wrapper'>
        <div className='qr-code-modal'>
          <img onClick={() => setIsModalOpened(false)} className='cross' src={crossIcon} alt='cross'></img>
          <h4>{t('scan_qr_code')}</h4>
          <p>{t('scan_qr_code_desc')}</p>
          {qrValue ?
            <QRCode
              className='qr-image'
              value={qrValue}
            />
            :
            <p>Loading...</p>}
        </div>
      </div>}
    </>
  )
}

export default QRCodemodal;