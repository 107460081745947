import React, { Component } from 'react';
import exchangeSuccess from '../../static/img/exchange-success.svg';
import usd from '../../static/img/usd.svg';
import arrowRight from '../../static/img/arrow-right.svg';
import cross from '../../static/img/cross.svg';
import './popupSuccess.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../static/img/copy.svg';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class PopupSuccess extends Component {

  constructor(props) {
    super(props);
  }


  handleLogoutClick = () => {
    this.props.history.push('/');
    //document.cookie = "session=";
    document.cookie = "clientToken=";
    document.location = "/";
  }


  render() {
    return (
      <div className='exchange-popup-success'>
        <div className='exchange-popup__container'>
          <img onClick={this.props.closePopup} className='popup-close' src={cross} alt='close'></img>
          <div className='popup-success'>
            <img src={exchangeSuccess} alt='exchange-success'></img>
            <div className='popup-status'>{this.props.t('exchange_accepted')}</div>
            <div className='popup-text'>{this.props.t('we_waiting')}</div>
            <div className='popup-row'>
              <div className='popup-value_row'>
                <img src={this.props.data.invert ? usd : this.props.data.logo} alt='usdt'></img>
                <div className='popup-value'>{this.props.data.invert ? `${this.props.data.valueCurrancy} USD` : `${this.props.data.valueCryptoCurrrancy} ${this.props.data.name}`}</div>
              </div>
              <img src={arrowRight} alt='arrow-right'></img>
              <div className='popup-value_row'>
                <img src={this.props.data.invert ? this.props.data.logo : usd} alt='usdt'></img>
                <div className='popup-value'>{this.props.data.invert ? `${this.props.data.valueCryptoCurrrancy} ${this.props.data.name}` : `${this.props.data.valueCurrancy} USD`}</div>
              </div>
            </div>
            {/* <p className='popup-info'>Visit our office at: <span className='popup-info_date'>{`${this.props.data.date} ${this.props.data.time}`}</span></p> */}

            <div className='popup__secpass__container'>
              <div className='popup__secpass__header'>
                <h4 className='popup__secpass__title'>{this.props.t('your_order_id')}: <span>{this.props.secPass}</span></h4>
                <CopyToClipboard text={this.props.secPass}>
                  <img className='popup__secpass__copy' src={copyIcon} alt='copy'></img>
                </CopyToClipboard>
              </div>

              <p className='popup__secpass__subtitle'>{this.props.t('save_code_desc')}</p>
              <hr width="100%" className='popup__line' />
              <div className='popup__wallet__header'>
                <h4 className='popup__wallet__title'>{this.props.t('wallet_address')}: <span>{this.props.data.wallet.length > 6 ? `${this.props.data.wallet.slice(0, 6)}...` : this.props.data.wallet}</span></h4>
                <CopyToClipboard text={this.props.data.wallet}>
                  <img className='popup__secpass__copy' src={copyIcon} alt='copy'></img>
                </CopyToClipboard>
              </div>
            </div>


          </div>
          <button onClick={() => {
            this.props.openHistory();
          }} className='popup-button'>{this.props.t('exchanges_history')}</button>
          <button onClick={this.handleLogoutClick} className='submitButtonSignupStyle'>{this.props.t('logout')}</button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(PopupSuccess));
