import React, { Component } from 'react'

import './strengthIndicator.css';
import { withTranslation } from 'react-i18next';

class PasswordStrengthIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: ""
    }
  }

  checkPasswordStrength = (lineNum) => {
    const score = this.props.score;

    let color = "#E5E4E7";

    switch (score) {
      case 1:
        color = "#DE5656";
        break;
      case 2:
        color = "#DE9756";
        break;
      case 3:
        color = "#DEC856";
        break;
      case 4:
        color = "#6ACF46";
        break;
      default:
        color = "#E5E4E7";
    }

    if (this.props.score == 0) {
      return "#E5E4E7";
    } else if (score >= lineNum) {
      return color;
    }

    return "#E5E4E7";
  }

  showPasswordStatus = () => {
    const score = this.props.score;
    let status = this.props.t("weak");

    switch (score) {
      case 1:
        status = this.props.t("weak");
        break;
      case 2:
        status = this.props.t("good");
        break;
      case 3:
        status = this.props.t("fine");
        break;
      case 4:
        status = this.props.t("excellent");
        break;
      default:
        status = this.props.t("weak");
    }


    if (this.props.setPasswordStrength) {
      this.props.setPasswordStrength(score);
    }

    this.setState({ status: status });
  }

  componentDidMount() {
    this.showPasswordStatus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.password !== this.props.password) {
      this.showPasswordStatus();
    }
  }


  render() {
    return (
      <div className='col'>
        <div className='row g-2' style={{ padding: "4px 0px" }}>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(1)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(2)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(3)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(4)
          }}></div>
        </div>
        <div className='row justify-content-start'>
          <span className='strength-status'>{this.props.t('password_strength')}: <span className='strength-status__value'>{this.state.status}</span></span>
        </div>
      </div>
    )
  }
}


export default withTranslation()(PasswordStrengthIndicator);