import React, { Component } from 'react';
import './datepicker.css';
import '../../static/css/bootstrap-grid.css';
import arrowDown from '../../static/img/chevron-down.svg';

export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.years = [
      2024,
      2025,
      2026,
      2027
    ];
    this.initialDay = (this.props.date && this.props.date[2]) ? parseInt(this.props.date[2]) : new Date().getDate();
    this.initialMonth = (this.props.date && this.props.date[1]) ? parseInt(this.props.date[1] - 1) : new Date().getMonth();
    this.initialYear = (this.props.date && this.props.date[0]) ? parseInt(this.props.date[0]) : new Date().getFullYear();

    this.state = {
      dayRef: React.createRef(null),
      monthRef: React.createRef(null),
      yearRef: React.createRef(null),
      isDayOpen: false,
      isMonthOpen: false,
      isYearOpen: false,
      day: this.initialDay,
      month: this.initialMonth,
      year: this.initialYear,
      days: this.generateDaysArray(this.initialMonth, this.initialYear),
      months: this.generateMonthsInArray(this.initialYear),
      years: this.props.isExchange ? this.years : this.generateYearsInArray(1950)
    }
  }


  generateDaysArray(month, year) {
    const isToday = new Date().getFullYear() === year && new Date().getMonth() === month;
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    let filteredArr = [...daysArray];

    if (this.props.isExchange && isToday) {
      filteredArr = daysArray.filter(item => {
        return item >= new Date().getDate()
      });
    } else if (!this.props.isExchange && isToday) {
      filteredArr = daysArray.filter(item => {
        return item <= new Date().getDate();
      });
    }


    return filteredArr;

  }

  generateMonthsInArray(year) {
    const isToday = new Date().getFullYear() === year;

    const monthsArray = [];
    let startIndex = 0;
    let endIndex = this.months.length;

    if (this.props.isExchange) {
      startIndex = new Date().getFullYear() === year ? new Date().getMonth() : 0;
    } else if (!this.props.isExchange && isToday) {
      endIndex = new Date().getMonth() + 1;
    }

    for (let i = startIndex; i < endIndex; i++) {
      monthsArray.push(this.months[i]);
    }

    return monthsArray;
  }

  generateYearsInArray(year) {
    const additionalYears = this.props.isExchange ? 3 : 0;
    const currentYear = new Date().getFullYear() + additionalYears;
    const yearsArray = [];
    for (let i = year; i <= currentYear; i++) {
      yearsArray.push(i);
    }

    return yearsArray;
  }

  handleClickOutside = (evt) => {
    if (this.state.isDayOpen && this.state.dayRef && this.state.dayRef.current && !this.state.dayRef.current.contains(evt.target)) {
      this.setState({ isDayOpen: false });
    } else if (this.state.isMonthOpen && this.state.monthRef && this.state.monthRef.current && !this.state.monthRef.current.contains(evt.target)) {
      this.setState({ isMonthOpen: false });
    } else if (this.state.isYearOpen && this.state.yearRef && this.state.yearRef.current && !this.state.yearRef.current.contains(evt.target)) {
      this.setState({ isYearOpen: false });
    }
  }

  handleInitDay = (month, year) => {
    const isToday = month === new Date().getMonth() && year === new Date().getFullYear();

    if (isToday) {
      if (this.props.isExchange && this.state.day < new Date().getDate()) {
        this.setState({ day: new Date().getDate() });
      } else if (!this.props.isExchange && this.state.day > new Date().getDate()) {
        this.setState({ day: new Date().getDate() });
      }
    }
  }

  handleInitMonth = (year) => {
    const isToday = year === new Date().getFullYear();

    if (isToday) {
      if (this.props.isExchange && this.state.month < new Date().getMonth()) {
        this.setState({ month: new Date().getMonth() });
      } else if (!this.props.isExchange && this.state.month > new Date().getMonth()) {
        this.setState({ month: new Date().getMonth() });
      }
    }


  }

  handleClearDate = () => {
    const initialDay = (this.props.date && this.props.date[2]) ? parseInt(this.props.date[2]) : new Date().getDate();
    const initialMonth = (this.props.date && this.props.date[1]) ? parseInt(this.props.date[1] - 1) : new Date().getMonth();
    const initialYear = (this.props.date && this.props.date[0]) ? parseInt(this.props.date[0]) : new Date().getFullYear();

    this.setState({
      day: initialDay,
      month: initialMonth,
      year: initialYear,
      days: this.generateDaysArray(initialMonth, initialYear),
      months: this.generateMonthsInArray(this.initialYear),
      years: this.props.isExchange ? this.years : this.generateYearsInArray(1950)
    });
  }

  componentDidMount() {
    this.props.onChange(`${this.state.year}-${this.state.month + 1}-${this.state.day}`);
    document.body.addEventListener("click", this.handleClickOutside);

    return () => {
      document.body.addEventListener("click", this.handleClickOutside);
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.day !== this.state.day || prevState.month !== this.state.month || prevState.year !== this.state.year) {
      this.props.onChange(`${this.state.year}-${this.state.month + 1}-${this.state.day}`);
    }

    if (prevState.month !== this.state.month) {
      this.setState({ days: this.generateDaysArray(this.state.month, this.state.year) });
      this.handleInitDay(this.state.month, this.state.year);
    }
    if (prevState.year !== this.state.year) {
      this.setState({
        months: this.generateMonthsInArray(this.state.year),
        days: this.generateDaysArray(this.state.month, this.state.year)
      });
      this.handleInitDay(this.state.month, this.state.year);
      this.handleInitMonth(this.state.year)
    }
  }

  render() {
    return (
      <div className='date-picker'>
        <div ref={this.state.dayRef} className='date-picker__input__container'>
          <input disabled={this.props.disabled} style={{ paddingRight: "35px" }} onClick={() => this.setState({ isDayOpen: true })} value={this.state.day < 10 ? `0${this.state.day}` : this.state.day} readOnly placeholder='Day'></input>
          {this.state.isDayOpen ? <img onClick={() => this.setState({ isDayOpen: false })} className='arrow-up' src={arrowDown} alt='arrow'></img> : <img onClick={() => this.setState({ isDayOpen: true })} src={arrowDown} alt='arrow-up'></img>}
          {this.state.isDayOpen && <div className='date-picker__container' style={{ position: "absolute" }}>
            <div className='date-picker__list'>
              {this.state.days.map(item => <div key={item} onClick={() => this.setState({ day: item, isDayOpen: false })} className='date-picker__list_item'>{item < 10 ? `0${item}` : item}</div>)}
            </div>
          </div>}
        </div>
        <div ref={this.state.monthRef} className='date-picker__input__container'>
          <input disabled={this.props.disabled} style={{ paddingRight: "35px" }} onClick={() => this.setState({ isMonthOpen: true })} value={this.months[this.state.month]} readOnly placeholder='Month'></input>
          {this.state.isMonthOpen ? <img onClick={() => this.setState({ isMonthOpen: false })} className='arrow-up' src={arrowDown} alt='arrow'></img> : <img onClick={() => this.setState({ isMonthOpen: true })} src={arrowDown} alt='arrow-up'></img>}
          {this.state.isMonthOpen && <div className='date-picker__container' style={{ position: "absolute" }}>
            <div className='date-picker__list'>
              {this.state.months.map(item => <div key={item} onClick={() => {
                this.setState({ month: this.months.indexOf(item), isMonthOpen: false });
                // this.handleInitDay(this.months.indexOf(item), this.state.year)
              }} className='date-picker__list_item'>{item}</div>)}
            </div>
          </div>}
        </div>
        <div ref={this.state.yearRef} className='date-picker__input__container'>
          <input disabled={this.props.disabled} style={{ paddingRight: "30px" }} onClick={() => this.setState({ isYearOpen: true })} value={this.state.year} readOnly placeholder='Year'></input>
          {this.state.isYearOpen ? <img onClick={() => this.setState({ isYearOpen: false })} className='arrow-up' src={arrowDown} alt='arrow'></img> : <img onClick={() => this.setState({ isYearOpen: true })} src={arrowDown} alt='arrow-up'></img>}
          {this.state.isYearOpen && <div className='date-picker__container' style={{ position: "absolute" }}>
            <div className='date-picker__list'>
              {this.state.years.map(item => <div key={item} onClick={() => {
                this.setState({ year: item, isYearOpen: false });
                // this.handleInitDay(this.state.month, item);
                // this.handleInitMonth(item);
              }} className='date-picker__list_item'>{item}</div>)}
            </div>
          </div>}
        </div>
      </div>
    )
  }
}
