import en from '../img/lang/en.svg';
import bolg from '../img/lang/bolg.svg';
import de from '../img/lang/de.svg';
import esp from '../img/lang/esp.svg';
import fr from '../img/lang/fr.svg';
import it from '../img/lang/it.svg';
import pol from '../img/lang/pol.svg';
import port from '../img/lang/port.svg';
import ru from '../img/lang/ru.svg';
import ua from '../img/lang/ua.svg';

export const languagesArr = [
  {
    lang: "English",
    flag: en,
    code: "en",
  },
  {
    lang: "Español",
    flag: esp,
    code: "es",
  },
  {
    lang: "Русский",
    flag: ru,
    code: "ru",
  },
  {
    lang: "Українська",
    flag: ua,
    code: "ua",
  },
  {
    lang: "Français",
    flag: fr,
    code: "fr",
  },
  {
    lang: "Deutsch",
    flag: de,
    code: "de",
  },
  {
    lang: "Italiano",
    flag: it,
    code: "it",
  },
  {
    lang: "Polski",
    flag: pol,
    code: "po",
  },
  {
    lang: "Português",
    flag: port,
    code: "portuguese",
  },
  {
    lang: "Български",
    flag: bolg,
    code: "bo",
  },
];