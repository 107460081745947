import React, { Component } from 'react';
import './footer.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';

export class Footer extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <footer id="footer">
          <div className="footer__container info_body">
            <p className="copyright">{this.props.t('footer_rights')}</p>
            <div className="info_link_wrap">
              <Link to="/privacy-policy"><p className="link">{this.props.t('privacy_policy')}</p></Link>
              <Link to="/terms-of-use"><p className="link">{this.props.t('terms_use')}</p></Link>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default withTranslation()(Footer);
