import './qrOpenButton.css';
import scanIcon from '../../../../static/img/scan.svg';

const QROpenButton = ({ setIsModalOpened }) => {
  return (
    <div onClick={setIsModalOpened} className='qr-open-button__wrapper'>
      <div className='qr-open-button'>
        <img src={scanIcon} alt='scanIcon'></img>
      </div>
    </div>
  )
}

export default QROpenButton