import React, { Component } from 'react';
import Logo from './logo/logo';
import ButtonMenu from './buttons/buttonMenu';
import clock from '../../static/img/clock.svg';
import arrowDown from '../../static/img/chevron-down.svg';
import exit from '../../static/img/exit.svg';
import '../../static/css/bootstrap-grid.css'
import './menu.css'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import lngIcon from '../../static/img/globe-alt.svg';
import lngActive from '../../static/img/globe-active.svg';
import { languagesArr } from '../../static/constants/languages';

class AppMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsRef: React.createRef(null),
            isSettingsOpen: false,
            isLangOpen: false,
            langRef: React.createRef(null),
        }
    }

    loadHistory = async () => {
        const api_url = await fetch('user/getHistory');
        const data = await api_url.json();

        if (data['result']) {
            this.props.setHistoryCount(data["history"].length);
        }
    }

    handleClickOutside = (evt) => {
        if ((this.state.isSettingsOpen || this.state.isLangOpen) && this.state.settingsRef && this.state.settingsRef.current && !this.state.settingsRef.current.contains(evt.target)) {
            this.setState({ isSettingsOpen: false, isLangOpen: false });
        }
    }

    handleLogoutClick = () => {
        // document.cookie = "session=";
        document.cookie = "clientToken=";
        document.location = "/";
        this.props.history.push('/');
    }

    handleChangeLang = (lang) => {
        localStorage.setItem("lang", lang);
        this.props.i18n.changeLanguage(lang).then(() => {
            this.forceUpdate(); 
        });
    }



    componentDidMount() {
        this.loadHistory();

        // const lang = localStorage.getItem("lang") || 'en'; // Load saved language from localStorage
        // this.props.i18n.changeLanguage(lang);


        document.body.addEventListener("click", this.handleClickOutside);

        return () => {
            document.body.addEventListener("click", this.handleClickOutside)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.User["auth"] !== this.props.User["auth"]) {
            this.loadHistory();
        }
    }

    render() {
        return (
            <div ref={this.state.settingsRef}>
                {/* Desktop header */}
                <div className='container menuBlock d-none d-md-block'>
                    <div className='row' style={{ height: '100%', alignItems: 'center' }}>
                        <div className='col-2'><Logo onClick={() => {
                            if (this.props.User["auth"]) {
                                this.props.history.push('/exchange');
                            } else {
                                this.props.history.push('/');
                            }

                        }} /></div>
                        <div className='col-10' style={{ justifyContent: "right", display: "flex" }}>

                            {this.props.User["auth"] && <div className='menu-history'>
                                <p onClick={this.props.openHistory} className='menu-history__text'>{this.props.t('my_history')}</p>
                                <div className='menu-history__counter'>{this.props.historyCount}</div>
                            </div>}

                            {this.props.User["auth"] && <div className='nameAuthUser'>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className='nameAuthUserHeaderShort__container'>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='nameAuthUserHeaderShort'>{this.props.User["firstName"][0]}{this.props.User["lastName"][0]}</div>
                                    </div>
                                    <div style={{ marginLeft: "12px" }} className='nameAuthUserHeader'>{this.props.User["firstName"]} {this.props.User["lastName"]}</div>
                                    <div>
                                        <img onClick={() => this.setState({ isSettingsOpen: true })} src={arrowDown} alt='arrowDown' style={{ cursor: "pointer" }}></img>
                                        {this.state.isSettingsOpen && <div className='profile-dropdown'>
                                            <img src={exit} alt='exit'></img>
                                            <div onClick={this.handleLogoutClick} className='profile-dropdown__text'>{this.props.t('logout')}</div>
                                        </div>}
                                    </div>
                                </div>
                            </div>}

                            {!this.props.User["auth"] && this.props.location.pathname !== "/" && <ButtonMenu Name={this.props.t('login')} />}

                            <div className='lang__container'>
                                <img
                                    className='lang__container__button'
                                    onClick={() => this.setState({ isLangOpen: !this.state.isLangOpen })}
                                    src={this.state.isLangOpen ? lngActive : lngIcon}
                                    alt='lngIcon'>
                                </img>
                                {this.state.isLangOpen && <div className='lang__container__languages'>
                                    {languagesArr.map(item => {
                                        return (
                                            <button disabled={localStorage.getItem("lang") === item.code} key={item.lang} onClick={() => this.handleChangeLang(item.code)} className='lang__container__item'>
                                                <img src={item.flag} alt={item.flag}></img>
                                                <p>{item.lang}</p>
                                            </button>
                                        )
                                    })}
                                </div>}
                            </div>

                            {/* {this.props.User["auth"] ? <ButtonMenu Name="Log Out" OpenWindow={() => { this.props.OpenWindow("login"); document.cookie = "session="; document.location = "/" }} ValueWindow={"logout"} /> : <ButtonMenu Name="Log In" OpenWindow={this.props.OpenWindow} ValueWindow={"login"} />} */}
                        </div>
                    </div>
                </div>

                {/* MobileHeader */}
                <div className='container menuBlock d-block d-md-none'>
                    <div className='row' style={{ height: '100%', alignItems: 'center' }}>
                        <div className='col-4'><Logo onClick={() => {
                            if (this.props.User["auth"]) {
                                this.props.history.push('/exchange');
                            } else {
                                this.props.history.push('/');
                            }
                        }} /></div>
                        <div className='col-8' style={{ justifyContent: "right", display: "flex", gap: "16px", position: "relative" }}>
                            {this.props.User["auth"] && <div className='menu-history'>
                                <img style={{ cursor: "pointer" }} width={24} height={24} onClick={this.props.openHistory} src={clock} alt='clock'></img>
                                <div className='menu-history__counter'>{this.props.historyCount}</div>
                            </div>}
                            {this.props.User["auth"] && <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                <div className='nameAuthUserHeaderShort' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{this.props.User["firstName"][0]}{this.props.User["lastName"][0]}</div>
                                <div>
                                    <img onClick={() => this.setState({ isSettingsOpen: true })} src={arrowDown} alt='arrowDown' style={{ cursor: "pointer" }}></img>
                                    {this.state.isSettingsOpen && <div className='profile-dropdown'>
                                        <img src={exit} alt='exit'></img>
                                        <div onClick={this.handleLogoutClick} className='profile-dropdown__text'>{this.props.t('logout')}</div>
                                    </div>}
                                </div>
                            </div>}

                            {!this.props.User["auth"] && this.props.location.pathname !== "/" && <ButtonMenu Name={this.props.t('login')} />}
                            <div className='lang__container'>
                                <img
                                    className='lang__container__button'
                                    onClick={() => this.setState({ isLangOpen: !this.state.isLangOpen })}
                                    src={this.state.isLangOpen ? lngActive : lngIcon}
                                    alt='lngIcon'>
                                </img>
                                {this.state.isLangOpen && <div className='lang__container__languages'>
                                    {languagesArr.map(item => {
                                        return (
                                            <div key={item.lang} className='lang__container__item'>
                                                <img src={item.flag} alt={item.flag}></img>
                                                <p>{item.lang}</p>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default withTranslation()(withRouter(AppMenu));