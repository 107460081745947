import React, { Component } from "react";
import "../../../../static/css/bootstrap-grid.css";
import "./step.css";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import { ssnObj } from "../../../../static/constants/ssnObj";
import { withTranslation } from "react-i18next";

class FinancialStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (event) => {
    let info = this.props.UserInfo;
    if (event.target.value[0] === "s" || event.target.value[0] === "S") {
      info.SSN = event.target.value;
    } else {
      info.SSN = event.target.value;
    }
    this.props.UpdateDataUser(info);
  };

  isValidSSN(input, typeSSN) {
    if (input.length === 0) {
      return true;
    }

    let maskPattern = "";

    if (typeSSN === ssnObj.ssn) {
      maskPattern = "\\d{3}-\\d{2}-\\d{4}";
    } else {
      maskPattern = "\\d{2}-\\d{7}";
    }
    const regex = new RegExp(`^${maskPattern}$`);

    return regex.test(input);
  }

  handleValidateInput = (str) => {
    const invalidChars = new RegExp("[!@#$%^&*()_+={}\\[\\]\\|:;\"'<>,.?/~`]");
    const onlyNumbers = /^[0-9]+$/;

    if (invalidChars.test(str) || onlyNumbers.test(str)) {
      return false;
    } else if (str.length > 0) {
      return true;
    }

    return null;
  };

  handleCheckTextInputs = () => {
    let info = this.props.UserInfo;

    return (
      this.handleValidateInput(info.currentEmployer) &&
      this.handleValidateInput(info.occupation)
    );
  };

  render() {
    return (
      <div className="StepBlock" style={{ height: "100%" }}>
        <div className="StepBlockHeader">
          <div className="stepNumber d-none d-md-flex justify-content-md-between">
            <p>
              {this.props.t('step')} {this.props.Steps.indexOf(this.props.SetStep) + 1}
              <span>/{this.props.Steps.length}</span>
            </p>
            <p>{this.props.t('application_form')}</p>
          </div>
          <h2>{this.props.t('financial_information')}</h2>
          <p>
            {this.props.t('personal_information_desc')}{" "}
            <Link to="/privacy-policy">
              <span className="privacy-policy__link">{this.props.t('privacy_policy')}</span>
            </Link>
          </p>
        </div>
        <div className="StepBlockMain">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div
              style={{ padding: "0 10px" }}
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12"
            >
              <p className="step__input__title_name">{this.props.t('current_employer')}</p>
              <input
                style={
                  this.handleValidateInput(
                    this.props.UserInfo.currentEmployer
                  ) !== null
                    ? {
                      border: `1px solid ${this.handleValidateInput(
                        this.props.UserInfo.currentEmployer
                      )
                        ? "#4FB54D"
                        : "#DE5656"
                        }`,
                    }
                    : {}
                }
                maxLength="30"
                type="text"
                placeholder={this.props.t('enter_employer')}
                onChange={(evt) => {
                  let info = this.props.UserInfo;
                  info.currentEmployer = evt.target.value.trim();
                  this.props.UpdateDataUser(info);
                }}
              ></input>
            </div>

            <div
              style={{ padding: "0 10px" }}
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12"
            >
              <p className="step__input__title_name">{this.props.t('job_title')}</p>
              <input
                style={
                  this.handleValidateInput(this.props.UserInfo.occupation) !==
                    null
                    ? {
                      border: `1px solid ${this.handleValidateInput(
                        this.props.UserInfo.occupation
                      )
                        ? "#4FB54D"
                        : "#DE5656"
                        }`,
                    }
                    : {}
                }
                type="text"
                placeholder={this.props.t('enter_job')}
                onChange={(evt) => {
                  let info = this.props.UserInfo;
                  info.occupation = evt.target.value.trim();
                  this.props.UpdateDataUser(info);
                }}
              ></input>
            </div>

            <div className="col-12">
              <p className="step__input__title">
                {this.props.t('socual_security_title')}
              </p>

              <div className="input-mask__container">
                <div>
                  <select
                    onChange={(evt) => {
                      let info = this.props.UserInfo;
                      info.typeSSN = evt.target.value;
                      this.props.UpdateDataUser(info);
                    }}
                  >
                    <option value={ssnObj.ssn}>{ssnObj.ssn}</option>
                    <option value={ssnObj.itin}>{ssnObj.itin}</option>
                  </select>
                </div>
                <div className="input-mask">
                  <InputMask
                    placeholder={this.props.t('enter_ssn')}
                    style={
                      this.props.UserInfo.SSN.length > 0 &&
                        this.isValidSSN(
                          this.props.UserInfo.SSN,
                          this.props.UserInfo.typeSSN
                        )
                        ? { border: "1px solid #4FB54D" }
                        : {}
                    }
                    mask={
                      this.props.UserInfo.typeSSN === ssnObj.ssn
                        ? "999-99-9999"
                        : "99-9999999"
                    }
                    value={this.props.UserInfo.SSN}
                    onChange={(evt) => {
                      let info = this.props.UserInfo;
                      info.SSN = evt.target.value;
                      this.props.UpdateDataUser(info);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="personal__bottom-text d-none d-md-block">
              {this.props.t('to_apply')}
            </div>
            <button
              disabled={
                !this.handleCheckTextInputs() ||
                !this.isValidSSN(
                  this.props.UserInfo.SSN,
                  this.props.UserInfo.typeSSN
                )
              }
              className="submitButtonStepStyle"
              onClick={() => {
                let info = this.props.UserInfo;
                info.step =
                  this.props.Steps[
                  this.props.Steps.indexOf(this.props.UserInfo.step) + 1
                  ];
                console.log(info);
                this.props.UpdateUser(info);
              }}
            >
              {this.props.t('next_step')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(FinancialStep);
