import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import InputWithLabel from '../../../../components/InputWithLabel/InputWithLabel';
import CustomDatePicker from '../../../../components/customDatePickerV2/customDatePicker';
import arrowDown from '../../../../static/img/chevron-down.svg';
import CountryList from '../countryList/CountryList';
import { nationalitiesList } from '../../../../static/constants/nationalitiesList';
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import { ssnObj } from '../../../../static/constants/ssnObj';
import { withTranslation } from 'react-i18next';

class SubmitStep extends Component {
    constructor(props) {
        super(props);
        this.autoCompleteRef = React.createRef();
        this.dateRef = React.createRef(null);
        this.state = {
            editUserInfo: null,
            submitDisabled: false,
            country: "",
            national: "",
            citizenship: "",
            countryRef: React.createRef(),
            nationalityRef: React.createRef(),
            citizenshipRef: React.createRef(),
            isFocused: false,
            isDateOpen: false,
            isNationalityFocused: false,
            isCitizenshipFocused: false,
            editStatus: false,
        }
    }

    validateCountry(value, type) {
        const filteredCountry = nationalitiesList.filter(({ en_short_name, nationality }) => {
            const item = type === "national" ? nationality : en_short_name;
            return item.toLowerCase() === value.toLowerCase();
        })

        if (filteredCountry.length === 0) {
            const info = { ...this.state.editUserInfo };

            if (type === "country") {
                this.setState({ country: "" });
                info.country = "";
            } else if (type === "national") {
                console.log("NATIONAL")
                this.setState({ national: "" });
                info.nationality = "";
            } else if (type === "citizen") {
                this.setState({ citizenship: "" });
                info.citizenship = "";
            }

            this.setState({ editUserInfo: info });
            //this.props.UpdateDataUser(info);
            return;
        }
    }

    handleClickOutside = (evt) => {
        if (this.state.isCitizenshipFocused && this.state.citizenshipRef && this.state.citizenshipRef.current && !this.state.citizenshipRef.current.contains(evt.target)) {
            this.setState({ isCitizenshipFocused: false });
        } else if (this.state.isFocused && this.state.countryRef && this.state.countryRef.current && !this.state.countryRef.current.contains(evt.target)) {
            this.setState({ isFocused: false });
        } else if (this.state.isNationalityFocused && this.state.nationalityRef && this.state.nationalityRef.current && !this.state.nationalityRef.current.contains(evt.target)) {
            this.setState({ isNationalityFocused: false });
        }
    }

    isValidSSN(input, typeSSN) {
        if (!input || input.length === 0) {
            return true;
        }

        let maskPattern = "";

        if (typeSSN === ssnObj.ssn) {
            maskPattern = "\\d{3}-\\d{2}-\\d{4}";
        } else {
            maskPattern = "\\d{2}-\\d{7}";
        }
        const regex = new RegExp(`^${maskPattern}$`);

        return regex.test(input);
    }

    submitChanges = async () => {
        const newInfo = { ...this.state.editUserInfo };
        this.setState({ editStatus: false });
        this.props.UpdateDataUser(newInfo);
    }

    submitReg = async () => {
        const info = { ...this.props.UserInfo };

        info.regType = this.props.regType;
        info.step = "Login";

        this.setState({ submitDisabled: true });
        await this.props.UpdateUser(info);
        this.setState({ submitDisabled: false });
        this.props.history.push("/exchange");
    }

    handleClearDate = () => {
        this.dateRef.current.handleClearDate();
    }

    onCancel = () => {
        this.handleClearDate();
        this.setState({
            editUserInfo: this.props.UserInfo,
            editStatus: false,
            country: this.props.UserInfo.country,
            national: this.props.UserInfo.nationality,
            citizenship: this.props.UserInfo.citizenship,
        });
    }

    handlePlaceSelected = (place, e) => {
        if (!place) {
            return;
        }

        let info = { ...this.state.editUserInfo };
        info.adress = e.value;
        this.setState({ editUserInfo: info });
    }


    componentDidMount() {
        this.setState({
            editUserInfo: this.props.UserInfo,
            country: this.props.UserInfo.country,
            national: this.props.UserInfo.nationality,
            citizenship: this.props.UserInfo.citizenship,
        });
        document.body.addEventListener("click", this.handleClickOutside);

        return () => {
            document.body.addEventListener("click", this.handleClickOutside)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.UserInfo !== this.props.UserInfo) {
            this.setState({
                editUserInfo: this.props.UserInfo,
                country: this.props.UserInfo.country,
                national: this.props.UserInfo.nationality,
                citizenship: this.props.UserInfo.citizenship,
            });
        }
    }

    validateFields = (info) => {

        if (info) {
            const isFirstName = info.firstName.length > 0;
            const isLastName = info.lastName.length > 0;
            const isBirth = info.birth.length > 0;
            const isCitizenship = info.citizenship.length > 0;
            const isNational = info.nationality.length > 0;
            const isCountry = info.country.length > 0;
            const isAddress = info.adress.length > 0;
            const isEmployer = info.currentEmployer.length > 0;
            const isOccupation = info.occupation.length > 0;
            // const isSSN = info.SSN.length > 0;

            return isFirstName && isLastName && isBirth && isCitizenship && isNational && isCountry && isAddress && isEmployer && isOccupation && this.isValidSSN(info.SSN, info.typeSSN);
        }
        return false;

    }

    render() {
        return (
            <>
                {this.state.editUserInfo && <div className='StepBlock' style={{ height: "100%" }}>
                    <div className='StepBlockHeader'>
                        <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                            <p>{this.props.t('step')} {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                            <p>{this.props.t('application_form')}</p>
                        </div>
                        <h2>{this.props.t('terms_and_conditions')}</h2>
                        <p>{this.props.t('application_almost_complete')}</p>
                        <p>{this.props.t('review_terms')}</p>
                    </div>
                    <div className='StepBlockMain'>
                        <h5 className='Submit__title'>{this.props.t('personal_information')}</h5>
                        <div className='Submit-inputs-grid'>
                            <InputWithLabel disabled={!this.state.editStatus} labelText={this.props.t('first_name')} value={this.state.editUserInfo.firstName} onChange={(e) => {
                                const info = { ...this.state.editUserInfo };
                                info.firstName = e.target.value;
                                this.setState({ editUserInfo: info });
                            }} />

                            <InputWithLabel disabled={!this.state.editStatus} labelText={this.props.t('last_name')} value={this.state.editUserInfo.lastName} onChange={(e) => {
                                const info = { ...this.state.editUserInfo };
                                info.lastName = e.target.value;
                                this.setState({ editUserInfo: info });
                            }} />

                            <div>
                                <p className='labelText'>{this.props.t('date_of_birth')}</p>
                                <CustomDatePicker ref={this.dateRef} disabled={!this.state.editStatus} date={this.state.editUserInfo.birth.split('-')} onChange={(date) => {
                                    const info = { ...this.state.editUserInfo };
                                    info.birth = date;
                                    this.setState({ editUserInfo: info });
                                }} />
                            </div>

                            <div>
                                <p className='labelText'>{this.props.t('nationality')}</p>
                                <div className='country-input'>
                                    <div ref={this.state.nationalityRef} className='country-input__container'>
                                        <input
                                            disabled={!this.state.editStatus}
                                            style={{ paddingRight: "30px" }}
                                            onBlur={() => {
                                                this.validateCountry(this.state.editUserInfo.nationality, "national");
                                            }}
                                            onClick={() => this.setState({ isNationalityFocused: true })}
                                            maxLength="64"
                                            type="text"
                                            value={this.state.editUserInfo.nationality}
                                            placeholder={this.props.t('enter_nationality')}
                                            onChange={(evt) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.nationality = evt.target.value.trim();
                                                this.setState({ editUserInfo: info, national: evt.target.value });
                                            }}></input>
                                        {this.state.isNationalityFocused ? <img className='arrow-rotate' onClick={() => this.setState({ isNationalityFocused: false })} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => this.setState({ isNationalityFocused: true })} src={arrowDown} alt='arrow-down'></img>}
                                    </div>
                                    {this.state.isNationalityFocused &&
                                        <CountryList
                                            mode="national"
                                            preventBlur={(e) => e.preventDefault()}
                                            country={this.state.national}
                                            setCountry={(value) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.nationality = value.trim();
                                                this.setState({ editUserInfo: info, national: value });
                                            }}
                                        />
                                    }
                                </div>
                            </div>

                            <div>
                                <p className='labelText'>{this.props.t('citizenship')}</p>
                                <div className='country-input'>
                                    <div ref={this.state.citizenshipRef} className='country-input__container'>
                                        <input
                                            disabled={!this.state.editStatus}
                                            style={{ paddingRight: "30px" }}
                                            onBlur={() => {
                                                this.validateCountry(this.state.editUserInfo.citizenship, "citizen");
                                            }}
                                            onClick={() => {
                                                this.setState({ isCitizenshipFocused: true });
                                            }}
                                            maxLength="64" type="text" value={this.state.editUserInfo.citizenship} placeholder="Select country" onChange={(evt) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.citizenship = evt.target.value.trim();
                                                this.setState({ editUserInfo: info, citizenship: evt.target.value });
                                            }}></input>
                                        {this.state.isCitizenshipFocused ? <img className='arrow-rotate' onClick={() => {

                                            this.setState({ isCitizenshipFocused: false });
                                        }} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => {
                                            this.setState({ isCitizenshipFocused: true })

                                        }} src={arrowDown} alt='arrow-down'></img>}
                                    </div>
                                    {this.state.isCitizenshipFocused &&
                                        <CountryList
                                            mode="country"
                                            preventBlur={(e) => e.preventDefault()}
                                            country={this.state.citizenship}
                                            setCountry={(value) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.citizenship = value.trim();
                                                this.setState({ editUserInfo: info, citizenship: value });
                                            }}
                                        />
                                    }
                                </div>

                            </div>

                            <div>
                                <p className='labelText'>{this.props.t('country_of_residence')}</p>
                                <div className='country-input'>
                                    <div ref={this.state.countryRef} className='country-input__container'>
                                        <input disabled={!this.state.editStatus} style={{ paddingRight: "30px" }} onBlur={() => {
                                            this.validateCountry(this.state.country, "country");
                                        }} onClick={() => this.setState({ isFocused: true })} maxLength="64" type="text" value={this.state.editUserInfo.country} placeholder={this.props.t('select_country')} onChange={(evt) => {
                                            const info = { ...this.state.editUserInfo };
                                            info.country = evt.target.value.trim();
                                            this.setState({ editUserInfo: info, country: evt.target.value });
                                        }}></input>
                                        {this.state.isFocused ? <img className='arrow-rotate' onClick={() => this.setState({ isFocused: false })} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => this.setState({ isFocused: true })} src={arrowDown} alt='arrow-down'></img>}
                                    </div>
                                    {this.state.isFocused &&
                                        <CountryList
                                            mode="country"
                                            preventBlur={(e) => e.preventDefault()}
                                            country={this.state.country}
                                            setCountry={(value) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.country = value.trim();
                                                this.setState({ editUserInfo: info, country: value.trim() });

                                            }}
                                        />
                                    }
                                </div>
                            </div>

                            <div className='Submit-inputs-grid__item'>
                                <p className='labelText'>{this.props.t('address')}</p>
                                <Autocomplete
                                    disabled={!this.state.editStatus}
                                    placeholder={this.props.t('select_address')}
                                    className='autocomplete'
                                    apiKey="AIzaSyCaHsRUO7Zrpc9UrisK-qkI0yyoLbt1lbk"
                                    onPlaceSelected={this.handlePlaceSelected}
                                    value={this.state.editUserInfo.adress}
                                    options={{
                                        types: ["address"],
                                    }}
                                    onChange={(e) => {
                                        const info = { ...this.state.editUserInfo };
                                        info.adress = e.target.value;
                                        this.setState({ editUserInfo: info });
                                    }}
                                    language="en"
                                    ref={this.autoCompleteRef}
                                />

                            </div>


                        </div>
                        <h5 className='Submit__title'>{this.props.t('financial_information')}</h5>

                        <div className='Submit-inputs-grid'>
                            <InputWithLabel disabled={!this.state.editStatus} labelText={this.props.t('current_employer')} value={this.state.editUserInfo.currentEmployer} onChange={(e) => {
                                const info = { ...this.state.editUserInfo };
                                info.currentEmployer = e.target.value;
                                this.setState({ editUserInfo: info });
                            }} />
                            <InputWithLabel disabled={!this.state.editStatus} labelText={this.props.t('job_title')} value={this.state.editUserInfo.occupation} onChange={(e) => {
                                const info = { ...this.state.editUserInfo };
                                info.occupation = e.target.value;
                                this.setState({ editUserInfo: info });
                            }} />

                            <div className='Submit-inputs-grid__item'>
                                <p className='labeText'>{this.props.t('socual_security_title')}</p>
                                <div className="input-mask__container">
                                    <div>
                                        <select
                                            disabled={!this.state.editStatus}
                                            value={this.state.editUserInfo.typeSSN}
                                            onChange={(evt) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.typeSSN = evt.target.value;
                                                this.setState({ editUserInfo: info });
                                            }}
                                        >
                                            <option value={ssnObj.ssn}>{ssnObj.ssn}</option>
                                            <option value={ssnObj.itin}>{ssnObj.itin}</option>
                                        </select>
                                    </div>
                                    <div className="input-mask">
                                        <InputMask
                                            disabled={!this.state.editStatus}
                                            placeholder={this.props.t('enter_ssn')}
                                            style={
                                                this.state.editUserInfo.SSN !== "" &&
                                                    this.isValidSSN(
                                                        this.state.editUserInfo.SSN,
                                                        this.state.editUserInfo.typeSSN
                                                    )
                                                    ? { border: "1px solid #4FB54D" }
                                                    : {}
                                            }
                                            mask={
                                                this.state.editUserInfo.typeSSN === ssnObj.ssn
                                                    ? "999-99-9999"
                                                    : "99-9999999"
                                            }
                                            value={this.state.editUserInfo.SSN}
                                            onChange={(evt) => {
                                                const info = { ...this.state.editUserInfo };
                                                info.SSN = evt.target.value;
                                                this.setState({ editUserInfo: info });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row' style={{ justifyContent: "flex-end", alignItems: "center" }}>
                            {/* <div className='personal__bottom-text d-none d-md-block'>*To apply, please complete this form in English.</div> */}
                            {this.state.editStatus && <button
                                className='editButtonStepStyle'
                                onClick={this.onCancel}>
                                {this.props.t('cancel')}
                            </button>}
                            {this.state.editStatus && <button
                                disabled={!this.validateFields(this.state.editUserInfo)}
                                className='submitButtonStepStyle'
                                onClick={this.submitChanges}>
                                {this.props.t('save_changes')}
                            </button>}

                            {!this.state.editStatus && <button
                                className='editButtonStepStyle'
                                onClick={() => this.setState({ editStatus: true })}>
                                {this.props.t('edit_info')}
                            </button>}
                            {!this.state.editStatus && <button
                                disabled={this.state.submitDisabled || !this.validateFields(this.props.UserInfo)}
                                className='submitButtonStepStyle'
                                onClick={this.submitReg}>
                                {this.props.t('submit')}
                            </button>}
                        </div>
                    </div>
                </div>}
            </>
        );
    }
}
export default withTranslation()(withRouter(SubmitStep));