import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    saveMissing: true,
    backend: {
      // The API endpoint where you fetch the translations
      loadPath: '/api/local/',

      // Configure custom headers for the API call
      requestOptions: (options, url) => {
        const currentLanguage = localStorage.getItem("lang") ?? "en";

        return {
          ...options,
          headers: {
            'Accept-Language': currentLanguage, // Set the Accept-Language header dynamically
          },
        };
      },
    },

  });
