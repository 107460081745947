import '../../static/css/bootstrap-grid.css'
import React, { Component, createRef } from 'react';
import '../../core/core.css'
import InputCurrency from './inputCurrency';
import logoUsd from "../../static/img/usd.png"
import logoExchange from "../../static/img/swap.png"
import PopupSuccess from '../../components/exchangePopupSuccess/PopupSuccess';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCountToFloat, formatCountToString } from '../../utils/priceFunctions';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { networksSelectArr } from '../../utils/selectArrays';
import CurrencySelectItem from '../../components/CurrencySelectItem/CurrencySelectItem';
import infoIcon from '../../static/img/info.svg';
import shieldIcon from '../../static/img/shield-check.svg';
import { withTranslation } from 'react-i18next';
import { capitalizeFirstWord } from '../../utils/capitalizeFirstWord';

class ExchangePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInfoVisible: false,
            upperInputRef: createRef(null),
            bottomInputRef: createRef(null),
            isSubmitDisabled: false,
            wallet: "",
            networkObj: null,
            secPass: "",
            valueCryptoCurrrancy: "",
            valueCurrancy: 0.0,
            setCryptoCurrancy: {
                "id": 0,
                "name": "USDT",
                "coefficient": 1,
                "comission": { "0": 5, "5000": 3 },
                "logo": logoUsd,
                "description": "5% fee for transactions < $5000  |  3% fee for transactions > $5000"
            },
            activeSettingCurrancy: '0',
            arrCryptoCurrancy: [],
            selectDate: "",
            selectTime: "",
            invert: false,
            isModalOpened: false,
            isHistoryOpened: false,
            modalData: {
                date: "",
                time: "",
                invert: false,
                valueCryptoCurrrancy: 0,
                valueCurrancy: 0
            },
            isDateOpen: false,
            isTimeOpen: false,
            timeInputValue: ""
        }
    }

    cryptoCurrencyToCurrency = (value = 0) => {


        if (typeof (this.state.setCryptoCurrancy["comission"][0]) == "string") {
            if (value <= parseFloat(this.state.setCryptoCurrancy["comission"][0].replace("$", ""))) {
                if (this.state.invert) {
                    this.setState({ valueCryptoCurrrancy: 0, valueCurrancy: value, activeSettingCurrancy: 0 })
                } else {
                    this.setState({ valueCryptoCurrrancy: value, valueCurrancy: 0, activeSettingCurrancy: 0 })
                }
                return 0
            }
        }



        let mainKey = Object.keys(this.state.setCryptoCurrancy["comission"])[0]
        Object.keys(this.state.setCryptoCurrancy["comission"]).forEach((element) => {
            if (parseFloat(element) > parseFloat(mainKey) && value > parseFloat(element)) {
                mainKey = element
            }
        })




        // Надо сделать обратный пересчет какой-то функцией
        // инверсия расчетов
        if (this.state.invert) {
            if (typeof (this.state.setCryptoCurrancy["comission"][mainKey]) == "string" && this.state.setCryptoCurrancy["comission"][mainKey].indexOf("$") > -1) {
                this.setState({ valueCryptoCurrrancy: value - parseFloat(this.state.setCryptoCurrancy["comission"][mainKey].replace("$", "")), valueCurrancy: value, activeSettingCurrancy: mainKey })
            } else {
                console.log(this.state.setCryptoCurrancy["comission"][mainKey])
                this.setState({ valueCryptoCurrrancy: value * (100 - this.state.setCryptoCurrancy["comission"][mainKey]) / 100, valueCurrancy: value, activeSettingCurrancy: mainKey })
            }
        }
        else {
            if (typeof (this.state.setCryptoCurrancy["comission"][mainKey]) == "string" && this.state.setCryptoCurrancy["comission"][mainKey].indexOf("$") > -1) {
                this.setState({ valueCryptoCurrrancy: value, valueCurrancy: Math.ceil(value - parseFloat(this.state.setCryptoCurrancy["comission"][mainKey].replace("$", ""))), activeSettingCurrancy: mainKey })
            } else {
                console.log("val")
                this.setState({ valueCryptoCurrrancy: value, valueCurrancy: Math.ceil(value * (100 - this.state.setCryptoCurrancy["comission"][mainKey]) / 100), activeSettingCurrancy: mainKey })
            }
        }


    }

    cryptoCurrencyToCurrencyInvers = (value = 0) => {
        let mainKey = Object.keys(this.state.setCryptoCurrancy["comission"])[0]
        let flag = true
        Object.keys(this.state.setCryptoCurrancy["comission"]).reverse().forEach((element) => {
            if (flag) {
                if (typeof (this.state.setCryptoCurrancy["comission"][element]) == "string") {
                    if (((parseFloat(this.state.setCryptoCurrancy["comission"][element].replace("$", "")) / 100) * value + value) > element) {
                        mainKey = element
                        flag = false
                    }
                } else {
                    if (((this.state.setCryptoCurrancy["comission"][element] / 100) * value + value) > element) {
                        mainKey = element
                        flag = false
                    }
                }
            }
        })



        if (this.state.invert) {
            if (typeof (this.state.setCryptoCurrancy["comission"][mainKey]) == "string" && this.state.setCryptoCurrancy["comission"][mainKey].indexOf("$") > -1) {
                this.setState({ valueCryptoCurrrancy: value, valueCurrancy: value + parseFloat(this.state.setCryptoCurrancy["comission"][mainKey].replace("$", "")), activeSettingCurrancy: mainKey })
            } else {
                console.log(this.state.setCryptoCurrancy["comission"][mainKey])
                this.setState({ valueCryptoCurrrancy: value, valueCurrancy: value + value * parseFloat(this.state.setCryptoCurrancy["comission"][mainKey] / 100), activeSettingCurrancy: mainKey })
            }
        }
        else {
            if (typeof (this.state.setCryptoCurrancy["comission"][mainKey]) == "string" && this.state.setCryptoCurrancy["comission"][mainKey].indexOf("$") > -1) {
                this.setState({ valueCryptoCurrrancy: value + parseFloat(this.state.setCryptoCurrancy["comission"][mainKey].replace("$", "")), valueCurrancy: value, activeSettingCurrancy: mainKey })
            }
            else {
                this.setState({ valueCryptoCurrrancy: value + value * parseFloat(this.state.setCryptoCurrancy["comission"][mainKey] / 100), valueCurrancy: value, activeSettingCurrancy: mainKey })
            }

        }
    }

    getCryptoCurrancyData = async () => {
        const api_url = await fetch(`user/getCryptoCurrancy/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });
        const data = await api_url.json();
        console.log(data);
        if (data['result'] && data['data'].length > 0) {
            this.setState({ arrCryptoCurrancy: data['data'], setCryptoCurrancy: data['data'][0] })
        }
    }

    createExchange = async () => {
        this.setState({ isSubmitDisabled: true });

        const jsonBody = JSON.stringify(
            {
                "count": this.state.invert ? formatCountToFloat(this.state.valueCurrancy) : formatCountToFloat(this.state.valueCryptoCurrrancy),
                "idCryptoCurrancy": this.state.setCryptoCurrancy["id"],
                "invert": this.state.invert,
                "network": this.state.networkObj.text,
                "wallet": this.state.wallet
            }
        );

        const api_url = await fetch(`user/createExchange/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "data=" + jsonBody,
        });
        const data = await api_url.json();


        this.setState({
            modalData: {
                date: this.state.selectDate.replace(/-/g, "/"),
                time: this.state.selectTime,
                invert: this.state.invert,
                valueCryptoCurrrancy: formatCountToString(this.state.valueCryptoCurrrancy),
                valueCurrancy: formatCountToString(this.state.valueCurrancy),
                name: this.state.setCryptoCurrancy["name"],
                logo: this.state.setCryptoCurrancy["logo"],
                wallet: data.wallet ?? "TMtHcypqsDAHaQRHdsxRNpt2gnNqrzRyCe"
            }
        })
        this.setState({ isSubmitDisabled: false });

        if (data['result']) {
            this.setState({
                isModalOpened: true,
                secPass: data["secPass"],
                networkObj: null,
                wallet: "",
                valueCurrancy: 0,
                valueCryptoCurrrancy: 0,
            });
            this.updateHistoryCount();
        } else {
            alert(data['message'])
        }
    }

    updateHistoryCount = () => {
        this.props.setHistoryCount(this.props.historyCount + 1);
    }


    handleNetworkSelect({ image1, text }) {
        this.setState({ networkObj: { image1, text } });
    }

    handleExchangeValidation() {
        if (!this.state.networkObj) {
            return false;
        }

        return this.state.networkObj.text && this.state.wallet;
    }

    componentDidMount = () => {
        this.getCryptoCurrancyData();
        if (!this.props.User['auth']) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.User !== this.props.User) {
            if (!this.props.User['auth']) {
                this.props.history.push('/');
            }
        }

        if (prevState.setCryptoCurrancy !== this.state.setCryptoCurrancy) {
            this.setState({
                networkObj: null,
            })
        }

        if (prevState.invert !== this.state.invert) {
            this.setState({
                networkObj: null,
                wallet: "",
            })
        }
    }

    render() {
        return (
            <div className='mainContainer' style={{ height: "initial" }}>
                {this.state.isModalOpened && <PopupSuccess
                    secPass={this.state.secPass}
                    openHistory={() => {
                        this.props.openHistory();
                        this.setState({ isModalOpened: false });
                    }}
                    data={this.state.modalData}
                    closePopup={() => this.setState({ isModalOpened: false })} />}

                <div className='exchangeBlock' style={{ marginBottom: "50px" }}>
                    <div className='container'>
                        <h1 style={{ marginTop: 0 }}>{this.props.t('exchange')}</h1>

                        <InputCurrency
                            setCurrancyObj={(value) => this.setState({ setCryptoCurrancy: value })}
                            currancy={this.state.invert ? "USD" : this.state.setCryptoCurrancy.name}
                            arrCryptoCurrancy={this.state.arrCryptoCurrancy}
                            type={"upper"}
                            invert={this.state.invert}
                            Header={this.props.t('you_pay')}
                            Active={true}
                            LogoCurrancy={this.state.invert ? logoUsd : this.state.setCryptoCurrancy["logo"]}
                            SetValue={(value) => {
                                this.cryptoCurrencyToCurrency(value);
                            }}
                            InitValue={this.state.invert ? this.state.valueCurrancy : this.state.valueCryptoCurrrancy}
                        />


                        <div className='iconExchange__container'>
                            <img className='iconExchange' onClick={() => this.setState({ invert: !this.state.invert })} src={logoExchange} alt='icon' />
                        </div>


                        <InputCurrency
                            setCurrancyObj={(value) => this.setState({ setCryptoCurrancy: value })}
                            currancy={this.state.invert ? this.state.setCryptoCurrancy.name : "USD"}
                            arrCryptoCurrancy={this.state.arrCryptoCurrancy}
                            type={"bottom"}
                            invert={this.state.invert}
                            Header={this.props.t('you_receive')}
                            Active={true}
                            LogoCurrancy={this.state.invert ? this.state.setCryptoCurrancy["logo"] : logoUsd}
                            SetValue={(value) => this.cryptoCurrencyToCurrencyInvers(value)}
                            InitValue={this.state.invert ? this.state.valueCryptoCurrrancy : this.state.valueCurrancy}
                        />


                        <div>
                            <div className='inputLogin mt-4 mb-4'>
                                <label>
                                    <div className='inputLogin__wallet__header'>
                                        <p>{capitalizeFirstWord(this.props.t('wallet'))}</p>
                                        <img
                                            className='info_icon'
                                            onMouseLeave={() => this.setState({ isInfoVisible: false })}
                                            onMouseEnter={() => this.setState({ isInfoVisible: true })}
                                            src={infoIcon} alt='infoIcon'>
                                        </img>

                                        {this.state.isInfoVisible &&
                                            <div
                                                onMouseLeave={() => this.setState({ isInfoVisible: false })}
                                                onMouseEnter={() => this.setState({ isInfoVisible: true })} className='inputLogin__wallet__header_info'
                                            >
                                                <img src={shieldIcon} alt='shield'></img>
                                                <p>{capitalizeFirstWord(this.props.t('check_crypto_wallet'))}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='inputLogin__wallet__input__container'>
                                        <input placeholder={this.props.t('enter_your_wallet')} value={this.state.wallet} onChange={(e) => this.setState({ wallet: e.target.value })}></input>
                                        {/* <button>Check</button> */}
                                    </div>

                                </label>
                            </div>

                            <CustomSelect
                                label={this.props.t('network')}
                                placeholder={this.props.t('select_network')}
                                value={this.state.networkObj}
                                options={networksSelectArr.map(({ image1, text }, index) => {
                                    if (this.state.setCryptoCurrancy.name === "USDT" && text !== "POLY") {
                                        return (
                                            <CurrencySelectItem
                                                key={index}
                                                image1={image1}
                                                text={text}
                                                isChosen={networksSelectArr?.text === text}
                                                onClick={() => this.handleNetworkSelect({ image1, text })}
                                            />
                                        );
                                    }
                                    else if (this.state.setCryptoCurrancy.name !== "USDT") {
                                        return (
                                            <CurrencySelectItem
                                                key={index}
                                                image1={image1}
                                                text={text}
                                                isChosen={networksSelectArr?.text === text}
                                                onClick={() => this.handleNetworkSelect({ image1, text })}
                                            />
                                        );
                                    }
                                })}
                            />
                        </div>
                        <button style={{ marginTop: 40 }} disabled={!this.handleExchangeValidation() || !this.state.valueCryptoCurrrancy || this.state.isSubmitDisabled} className='submitButtonLoginStyle' onClick={() => {
                            this.createExchange();
                        }}>{this.props.t('submit')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ExchangePage));