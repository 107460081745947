import React from 'react';
import checkGrey from '../../../../static/img/check-circle.svg';
import checkGreen from '../../../../static/img/check-circle-green.svg';

const PasswordCheck = ({ text, isValid }) => {
    return (
        <div className='password-check'>
            <img src={isValid ? checkGreen : checkGrey} alt='check'></img>
            <p>{text}</p>
        </div>
    )
}

export default PasswordCheck