import React, { Component } from 'react';
import './termsOfUse.css';
import backIcon from '../../static/img/back.png';
import '../../static/css/bootstrap-grid.css';
import { withTranslation } from 'react-i18next';

class TermsOfUsePage extends Component {
    render() {
        return (
            <div className='container'>
                {/* <div className='backButtonPrivacy' onClick={() => this.props.Back()}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} />Back</div> */}
                <div className='policy'>
                    <div>
                        <h1 className='policy__title'>{this.props.t('terms_use')}</h1>
                        <p className='policy__date'>{this.props.t('effective_date')}</p>
                    </div>
                    <div className='policy__rules'>
                        <div>
                            <h2 className='policy__rules_main-item'>1. {this.props.t('introduction')}</h2>
                            <p>{this.props.t('terms_introduction_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>2. {this.props.t('eligibility')}</h2>
                            <p>{this.props.t('eligibility_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>3. {this.props.t('terms_currency_exchange')}</h2>
                            <ul>
                                <li>{this.props.t('terms_currency_exchange_desc1')}</li>
                                <li>{this.props.t('terms_currency_exchange_desc2')}</li>
                                <li>{this.props.t('terms_currency_exchange_desc3')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>4. {this.props.t('use_of_services')}</h2>
                            <ul>
                                <li>{this.props.t('use_of_services_desc1')}</li>
                                <li>{this.props.t('use_of_services_desc2')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>5. {this.props.t('accounts_and_security')}</h2>
                            <ul>
                                <li>{this.props.t('accounts_and_security_desc1')}</li>
                                <li>{this.props.t('accounts_and_security_desc2')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>6. {this.props.t('intellectual_property')}</h2>
                            <p>{this.props.t('intellectual_property_desc1')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>7. {this.props.t('termination')}</h2>
                            <p>{this.props.t('termination_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>8. {this.props.t('disclaimers')}</h2>
                            <p>{this.props.t('disclaimers_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>9. {this.props.t('limitation_of_liability')}</h2>
                            <p>{this.props.t('limitation_of_liability_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>10. {this.props.t('indemnification')}</h2>
                            <p>{this.props.t('indemnification_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>11. {this.props.t('changes_of_terms')}</h2>
                            <p>{this.props.t('changes_of_terms_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>12. {this.props.t('governing_law')}</h2>
                            <p>{this.props.t('governing_law_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>13. {this.props.t('contact_us')}</h2>
                            <p className='mb-4'>{this.props.t('terms_contact_us_desc')}: <b>contact@what-money.miami</b></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(TermsOfUsePage);
