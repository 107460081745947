export const dateFilterlist = [
  {
    label: "Today",
    value: "today"
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
  },
  {
    label: "Last 90 days",
    value: "last_90_days",
  },
  {
    label: "Last year",
    value: "last_year",
  },
];