import React, { Component } from 'react';
import CountryList from '../countryList/CountryList';
import CustomDatePicker from '../../../../components/customDatePickerV2/customDatePicker';
import arrowDown from '../../../../static/img/chevron-down.svg';

import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import { nationalitiesList } from '../../../../static/constants/nationalitiesList';
import Autocomplete from "react-google-autocomplete";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class PersonalStep extends Component {
    constructor(props) {
        super(props);
        this.autoCompleteRef = React.createRef();
        this.state = {
            country: "",
            national: "",
            citizenship: "",
            countryRef: React.createRef(),
            nationalityRef: React.createRef(),
            citizenshipRef: React.createRef(),
            isFocused: false,
            isDateOpen: false,
            isNationalityFocused: false,
            isCitizenshipFocused: false,
        }
    }

    validateCountry(value, type) {
        const filteredCountry = nationalitiesList.filter(({ en_short_name, nationality }) => {
            const item = type === "national" ? nationality : en_short_name;
            return item.toLowerCase() === value.toLowerCase();
        })

        if (filteredCountry.length === 0) {
            let info = this.props.UserInfo;

            if (type === "country") {
                this.setState({ country: "" });
                info.country = "";
            } else if (type === "national") {
                this.setState({ national: "" });
                info.national = "";
            } else if (type === "citizen") {
                this.setState({ citizenship: "" });
                info.citizenship = "";
            }

            this.props.UpdateDataUser(info);
            return;
        }
    }



    handleValidateInput = (str, includeNum) => {
        let invalidChars = null;

        if (includeNum) {
            invalidChars = new RegExp('[!@#$%^&*()_+={}\\[\\]\\|:;"\'<>,.?/~`]');
        } else {
            invalidChars = new RegExp('[!@#$%^&*()_+={}\\[\\]\\|:;"\'<>,.?/~`0-9]');
        }

        if (invalidChars.test(str)) {
            return false;
        } else if (invalidChars.test(str) == false && str.length > 0) {
            return true;
        }

        return null;
    }

    handleCheckTextInputs = () => {
        let info = this.props.UserInfo;

        return this.handleValidateInput(info.nationality, true) && this.handleValidateInput(info.citizenship, true) && this.handleValidateInput(info.firstName) && this.handleValidateInput(info.lastName);
    }

    handleClickOutside = (evt) => {
        if (this.state.isCitizenshipFocused && this.state.citizenshipRef && this.state.citizenshipRef.current && !this.state.citizenshipRef.current.contains(evt.target)) {
            this.setState({ isCitizenshipFocused: false });
        } else if (this.state.isFocused && this.state.countryRef && this.state.countryRef.current && !this.state.countryRef.current.contains(evt.target)) {
            this.setState({ isFocused: false });
        } else if (this.state.isNationalityFocused && this.state.nationalityRef && this.state.nationalityRef.current && !this.state.nationalityRef.current.contains(evt.target)) {
            this.setState({ isNationalityFocused: false });
        }
    }

    handlePlaceSelected = (place, e) => {
        if (!place) {
            return;
        }

        let info = this.props.UserInfo;
        info.adress = e.value;
        this.props.UpdateDataUser(info);
    }

    componentDidMount() {
        document.body.addEventListener("click", this.handleClickOutside);

        return () => {
            document.body.addEventListener("click", this.handleClickOutside)
        };
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>{this.props.t('step')} {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>{this.props.t('application_form')}</p>
                    </div>
                    <h2>{this.props.t('personal_information')}</h2>
                    <p>{this.props.t('personal_information_desc')} <Link to="/privacy-policy"><span className='privacy-policy__link'>{this.props.t('Privacy Policy')}</span></Link></p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row' style={{ paddingBottom: '30px' }}>
                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title_first-name'>{this.props.t('first_name')}</p>
                            <input className='inputName' style={this.handleValidateInput(this.props.UserInfo.firstName) !== null ? {
                                border: `1px solid ${this.handleValidateInput(this.props.UserInfo.firstName) ? "#4FB54D" : "#DE5656"}`,
                            } : {}} maxLength="40" type="text" placeholder={this.props.t('type_first_name')} value={this.props.UserInfo.firstName} onChange={(evt) => {
                                let info = this.props.UserInfo;
                                info.firstName = evt.target.value.trim();
                                this.props.UpdateDataUser(info);
                            }}></input>
                        </div>

                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title_last-name'>{this.props.t('last_name')}</p>
                            <input className='inputName' style={this.handleValidateInput(this.props.UserInfo.lastName) !== null ? {
                                border: `1px solid ${this.handleValidateInput(this.props.UserInfo.lastName) ? "#4FB54D" : "#DE5656"}`,
                            } : {}} maxLength="40" type="text" placeholder={this.props.t('type_last_name')} value={this.props.UserInfo.lastName} onChange={(evt) => {
                                let info = this.props.UserInfo;
                                info.lastName = evt.target.value.trim();
                                this.props.UpdateDataUser(info);
                            }}></input>
                        </div>

                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title'>{this.props.t('date_of_birth')}</p>
                            <CustomDatePicker date={this.props.UserInfo.birth.split('-')} onChange={(date) => {
                                let info = this.props.UserInfo;
                                info.birth = date;
                                this.props.UpdateDataUser(info);
                            }} />
                        </div>

                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title'>{this.props.t('nationality')}</p>
                            <div className='country-input'>
                                <div ref={this.state.nationalityRef} className='country-input__container'>
                                    <input style={{ paddingRight: "30px" }} onBlur={() => {
                                        this.validateCountry(this.state.national, "national");
                                    }} onClick={() => this.setState({ isNationalityFocused: true })} maxLength="30" type="text" value={this.state.national} placeholder={this.props.t('enter_nationality')} onChange={(evt) => {
                                        this.setState({ national: evt.target.value });
                                        let info = this.props.UserInfo;
                                        info.nationality = evt.target.value.trim();
                                        this.props.UpdateDataUser(info);
                                    }}></input>
                                    {this.state.isNationalityFocused ? <img className='arrow-rotate' onClick={() => this.setState({ isNationalityFocused: false })} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => this.setState({ isNationalityFocused: true })} src={arrowDown} alt='arrow-down'></img>}
                                </div>
                                {this.state.isNationalityFocused &&
                                    <CountryList
                                        mode="national"
                                        preventBlur={(e) => e.preventDefault()}
                                        country={this.state.national}
                                        setCountry={(value) => {
                                            this.setState({ national: value });
                                            let info = this.props.UserInfo;
                                            info.nationality = value.trim();
                                            this.props.UpdateDataUser(info);
                                        }}
                                    />
                                }
                            </div>

                        </div>

                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title'>{this.props.t('citizenship')}</p>
                            <div className='country-input'>
                                <div ref={this.state.citizenshipRef} className='country-input__container'>
                                    <input
                                        style={{ paddingRight: "30px" }}
                                        onBlur={() => {
                                            this.validateCountry(this.state.citizenship, "citizen");
                                        }}
                                        onClick={() => {
                                            this.setState({ isCitizenshipFocused: true });
                                        }}
                                        maxLength="30" type="text" value={this.state.citizenship} placeholder={this.props.t('select_country')} onChange={(evt) => {
                                            this.setState({ citizenship: evt.target.value });
                                            let info = this.props.UserInfo;
                                            info.citizenship = evt.target.value.trim();
                                            this.props.UpdateDataUser(info);
                                        }}></input>
                                    {this.state.isCitizenshipFocused ? <img className='arrow-rotate' onClick={() => {

                                        this.setState({ isCitizenshipFocused: false });
                                    }} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => {
                                        this.setState({ isCitizenshipFocused: true })

                                    }} src={arrowDown} alt='arrow-down'></img>}
                                </div>
                                {this.state.isCitizenshipFocused &&
                                    <CountryList
                                        mode="country"
                                        preventBlur={(e) => e.preventDefault()}
                                        country={this.state.citizenship}
                                        setCountry={(value) => {
                                            this.setState({ citizenship: value });
                                            let info = this.props.UserInfo;
                                            info.citizenship = value.trim();
                                            this.props.UpdateDataUser(info);
                                        }}
                                    />
                                }
                            </div>

                        </div>

                        <div style={{ padding: "0 10px" }} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='step__input__title'>{this.props.t('country_of_residence')}</p>
                            <div className='country-input'>
                                <div ref={this.state.countryRef} className='country-input__container'>
                                    <input style={{ paddingRight: "30px" }} onBlur={() => {
                                        this.validateCountry(this.state.country, "country");
                                    }} onClick={() => this.setState({ isFocused: true })} maxLength="30" type="text" value={this.state.country} placeholder={this.props.t('select_country')} onChange={(evt) => {
                                        this.setState({ country: evt.target.value })
                                        let info = this.props.UserInfo;
                                        info.country = evt.target.value.trim();
                                        this.props.UpdateDataUser(info)
                                    }}></input>
                                    {this.state.isFocused ? <img className='arrow-rotate' onClick={() => this.setState({ isFocused: false })} src={arrowDown} alt='arrow-down'></img> : <img className='arrow-normal' onClick={() => this.setState({ isFocused: true })} src={arrowDown} alt='arrow-down'></img>}
                                </div>
                                {this.state.isFocused &&
                                    <CountryList
                                        mode="country"
                                        preventBlur={(e) => e.preventDefault()}
                                        country={this.state.country}
                                        setCountry={(value) => {
                                            this.setState({ country: value });
                                            let info = this.props.UserInfo;
                                            info.country = value.trim();
                                            this.props.UpdateDataUser(info)
                                        }}
                                    />
                                }
                            </div>

                        </div>

                        <div style={{ padding: "0 10px" }} className='col-12'>
                            <p className='step__input__title'>{this.props.t('address')}</p>
                            {/* <GooglePlacesAutocomplete
                                apiKey="AIzaSyCaHsRUO7Zrpc9UrisK-qkI0yyoLbt1lbk"
                                apiOptions={{ language: 'en' }}
                            /> */}
                            {/* <input type="text" placeholder="Enter your address" onChange={(evt) => {
                                let info = this.props.UserInfo;
                                info.adress = evt.target.value.trim();
                                this.props.UpdateDataUser(info)
                            }}></input> */}
                            <Autocomplete
                                placeholder={this.props.t('select_address')}
                                className='autocomplete'
                                apiKey="AIzaSyCaHsRUO7Zrpc9UrisK-qkI0yyoLbt1lbk"
                                onPlaceSelected={this.handlePlaceSelected}
                                options={{
                                    types: ["address"],
                                }}
                                onChange={(e) => {
                                    let info = this.props.UserInfo;
                                    info.adress = e.target.value;
                                    this.props.UpdateDataUser(info);
                                }}
                                language="en"
                                ref={this.autoCompleteRef}
                            />

                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='personal__bottom-text d-none d-md-block'>{this.props.t('to_apply')}</div>
                        <button disabled={this.props.UserInfo.adress === "" || this.props.UserInfo.birth === "" || this.props.UserInfo.country === "" || !this.handleCheckTextInputs()} className='submitButtonStepStyle' onClick={() => { let info = this.props.UserInfo; info.step = 'Financial'; info.country = this.state.country; console.log(info); this.props.UpdateUser(info) }}>{this.props.t('next_step')}</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(PersonalStep);