import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import PasswordStrengthIndicator from '../../../../components/passwordStrengthIndicator/PasswordStrengthIndicator';
import eyeIcon from '../../../../static/img/eye.svg';
import eyeSlashIcon from '../../../../static/img/eye-slash.svg';
import PasswordCheck from '../passwordCheck/PasswordCheck';
import { withTranslation } from 'react-i18next';

class PasswordStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmPassword: "",
            isVisiblePassword: false,
            isVisibleRepeat: false,
            passwordStrength: 0,
        }
    }

    hasWhiteSpaces = () => {
        const password = this.props.UserInfo.password;

        return password.length > 0 && password.indexOf(' ') < 0;
    }

    hasEightCharacters = () => {
        const password = this.props.UserInfo.password;

        return password.length >= 8;
    }

    checkCaseCharacters = () => {
        const password = this.props.UserInfo.password;

        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);

        return hasUppercase && hasLowercase && hasNumber;
    }

    hasSpecialCharacters = () => {
        const password = this.props.UserInfo.password;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasSpecialChar;
    }

    isValidPassword = () => {
        const hasWhiteSpaces = this.hasWhiteSpaces() ? 1 : 0;
        const hasEightCharacters = this.hasEightCharacters() ? 1 : 0;
        const checkCaseCharacters = this.checkCaseCharacters() ? 1 : 0;
        const hasSpecialCharacters = this.hasSpecialCharacters() ? 1 : 0;

        const result = hasWhiteSpaces + hasEightCharacters + checkCaseCharacters + hasSpecialCharacters;

        return result;
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>{this.props.t('step')} {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>{this.props.t('application_form')}</p>
                    </div>
                    <h2>{this.props.t('create_password')}</h2>
                    <p>{this.props.t('secure_details')}</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row' style={{ paddingBottom: '30px' }}>

                        <div className='col-12'>
                            <p className='step__input__title_first-name' style={{ marginBottom: "12px" }}>{this.props.t('your_password')}</p>
                            {/* <p style={{ fontSize: "12px", marginTop: "0px", marginBottom: "18px" }}>The password must include at least one uppercase letter and one number, and be a minimum of 9 characters in length.</p> */}

                            <div className='password-input'>
                                <input maxLength="64" value={this.props.UserInfo.password} type={`${this.state.isVisiblePassword ? "text" : "password"}`} placeholder={this.props.t('password')} onChange={(evt) => {
                                    let info = this.props.UserInfo;
                                    info.password = evt.target.value;

                                    this.props.UpdateDataUser(info)
                                }}></input>
                                <img onClick={() => this.setState({ isVisiblePassword: !this.state.isVisiblePassword })} src={this.state.isVisiblePassword ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                            </div>
                            <span className='password-checks__title'>{this.props.t('password_must_have')}</span>
                            <div className='password-checks'>
                                <PasswordCheck isValid={this.hasEightCharacters()} text={this.props.t('password_must_have_desc1')} />
                                <PasswordCheck isValid={this.checkCaseCharacters()} text={this.props.t('password_must_have_desc2')} />
                                <PasswordCheck isValid={this.hasSpecialCharacters()} text={this.props.t('password_must_have_desc3')} />
                                <PasswordCheck isValid={this.hasWhiteSpaces()} text={this.props.t('password_must_have_desc4')} />
                            </div>
                            {this.props.UserInfo.password.length > 0 && <PasswordStrengthIndicator score={this.isValidPassword()} password={this.props.UserInfo.password} />}
                        </div>
                        <div className='col-12'>
                            <p className='step__input__title'>{this.props.t('confirm_password')}</p>
                            <div className='password-input'>
                                <input maxLength="64" type={`${this.state.isVisibleRepeat ? "text" : "password"}`} placeholder={this.props.t('password')} value={this.state.confirmPassword} onChange={(evt) => this.setState({ confirmPassword: evt.target.value })}></input>
                                <img onClick={() => this.setState({ isVisibleRepeat: !this.state.isVisibleRepeat })} src={this.state.isVisibleRepeat ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='personal__bottom-text d-none d-md-block'>{this.props.t('to_apply')}</div>
                        <button disabled={this.isValidPassword() < 4 || this.props.UserInfo.password !== this.state.confirmPassword} className='submitButtonStepStyle' onClick={() => { let info = this.props.UserInfo; info.step = this.props.Steps[this.props.Steps.indexOf(this.props.UserInfo.step) + 1]; console.log(info); this.props.UpdateUser(info) }}>{this.props.t('next_step')}</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(PasswordStep);