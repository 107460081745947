
import trc20 from '../static/img/trc20.svg';
import erc20 from '../static/img/erc20.svg';
import poly from '../static/img/poly.svg';


export const networksSelectArr = [
    {
        image1: trc20,
        text: "TRC20",
    },
    {
        image1: erc20,
        text: "ERC20",
    },
    {
        image1: poly,
        text: "POLY",
    },
];

