import '../../static/css/bootstrap-grid.css'
import React, { Component, createRef } from 'react';
import '../../core/core.css';
import { NumericFormat } from 'react-number-format';
import { formatNumber, fromStringToFloat } from '../../utils/priceFunctions';
import arrowTop from '../../static/img/arrow-roll-top.svg';
import arrowBottom from '../../static/img/arrow-roll-bottom.svg';
import searchIcon from '../../static/img/search.svg';
import checkIcon from '../../static/img/check.svg';
import { withTranslation } from 'react-i18next';

class InputCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectOpened: false,
      inputRef: createRef(null),
      isDisabled: false,
      searchInput: "",
    }
  }


  setValCurransy = (value) => {
    if (!value) {
      this.props.SetValue(0);
      return;
    }

    const valueFloat = fromStringToFloat(value);

    const isDollar = this.props.type === "bottom" && !this.props.invert;
    const result = isDollar ? Math.ceil(valueFloat) : valueFloat;
    console.log(Math.ceil(valueFloat))

    this.props.SetValue(result)
  }


  handleClickOutside = (evt) => {
    if (this.state.isSelectOpened && this.state.inputRef && this.state.inputRef.current && !this.state.inputRef.current.contains(evt.target)) {
      this.setState({ isSelectOpened: false, searchInput: "" });
    }
  }

  componentDidMount() {
    const isDisabled = (this.props.type === "upper" && !this.props.invert) || ((this.props.type === "bottom" && this.props.invert));
    this.setState({ isDisabled: isDisabled });
    document.addEventListener("mousedown", this.handleClickOutside);
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.invert !== this.props.invert) {
      const isDisabled = (this.props.type === "upper" && !this.props.invert) || ((this.props.type === "bottom" && this.props.invert));
      this.setState({ isDisabled: isDisabled });
      this.setValCurransy(this.props.InitValue);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <div className='inputCurrency'>
        <h3>{this.props.Header}</h3>
        <div className='inputCurrency__row'>

          <div className='inputCurrency__currency__container'>
            <div onClick={() => {
              if (this.state.isDisabled) {
                this.setState({ isSelectOpened: !this.state.isSelectOpened })
              }
            }} className='inputCurrency__currency__input'>
              <img src={this.props.LogoCurrancy} />
              <p>{this.props.currancy}</p>
              {this.state.isDisabled && <img className='inputCurrency__currency__arrow' src={this.state.isSelectOpened ? arrowTop : arrowBottom} alt='arrow-bottom'></img>}
            </div>


            {this.state.isSelectOpened && <div ref={this.state.inputRef} className='inputCurrency__currency__select'>
              <div className='inputCurrency__currency__select__input'>
                <img className='select__input__icon' src={searchIcon} alt='searchIcon'></img>
                <input
                  value={this.state.searchInput}
                  placeholder={this.props.t('search')}
                  onChange={(e) => this.setState({ searchInput: e.target.value })}
                >
                </input>
                <div className='inputCurrency__currency__select__container'>
                  {this.props.arrCryptoCurrancy && this.props.arrCryptoCurrancy.filter(item => item.name.toLowerCase().includes(this.state.searchInput.toLowerCase())).map(item => (
                    <div
                      key={item.id}
                      className='inputCurrency__currency__select__item'
                      onClick={() => this.props.setCurrancyObj(item)}
                    >
                      <img src={item.logo} alt='icon'></img>
                      <p>{item.name}</p>
                      {item.name === this.props.currancy && <img className='check-icon' src={checkIcon} alt='checkIcon'></img>}
                    </div>
                  ))}
                </div>
              </div>
            </div>}
          </div>

          <div className='inputCurrency__value'>
            <NumericFormat
              decimalScale={(this.props.type === "bottom" && !this.props.invert) ? 0 : 2}
              step={0.1}
              allowNegative={false}
              allowLeadingZeros={false}
              placeholder={"0,00"}
              thousandSeparator="."
              decimalSeparator=","
              value={this.props.InitValue}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (!floatValue) {
                  return true;
                }

                return floatValue <= 1000000000 && floatValue >= 1;
              }}
              onChange={(e) => {
                this.setValCurransy(e.target.value);
              }} />
          </div>
        </div>
      </div >
    );
  }
}

export default withTranslation()(InputCurrency);