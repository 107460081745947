import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';

class TermsStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false
        }
    }

    submitTerms = async () => {
        let info = this.props.UserInfo;
        info.conditions = this.state.check;
        info.step =
            this.props.Steps[
            this.props.Steps.indexOf(this.props.UserInfo.step) + 1
            ];
        await this.props.UpdateUser(info);
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>{this.props.t('step')} {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>{this.props.t('application_form')}</p>
                    </div>
                    <h2>{this.props.t('terms_and_conditions')}</h2>
                    <p>{this.props.t('application_almost_complete')}</p>
                    <p>{this.props.t('review_terms')}</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row'>
                        <div className='col-12'>
                            <ul>
                                <li>{this.props.t('review_terms_desc1')}</li>
                                <li>{this.props.t('review_terms_desc2')}</li>
                                <li>{this.props.t('review_terms_desc3')}</li>
                                <li>{this.props.t('review_terms_desc4')}</li>
                                <li>{this.props.t('review_terms_desc5')}</li>
                            </ul>
                        </div>
                        <div>
                            <p style={{ fontWeight: 400, fontSize: 12, lineHeight: 1.5, color: "#807C90" }}>{this.props.t('review_terms_desc6')}</p>
                            <p style={{ fontWeight: 400, fontSize: 12, lineHeight: 1.5, color: "#807C90", borderBottom: "1px solid #CECED3", paddingBottom: 20 }}>{this.props.t('review_terms_desc7')}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginRight: "15px" }}>
                                    <input onChange={(evt) => { this.setState({ check: !this.state.check }); let info = this.props.UserInfo; info.conditions = !this.state.check; this.props.UpdateDataUser(info) }} type="checkbox" style={{ width: 24, height: 24, border: "2px solid #090909" }} />
                                </div>
                                <p style={{ display: "inline-block", margin: 0 }}>{this.props.t('review_terms_agree')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='personal__bottom-text d-none d-md-block'>{this.props.t('to_apply')}</div>
                        <button
                            disabled={!this.state.check}
                            className='submitButtonStepStyle'
                            onClick={this.submitTerms}>{this.props.t('next_step')}</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(withRouter(TermsStep));