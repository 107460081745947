import React, { Component } from 'react';
import './privacyPolicy.css';
import '../../static/css/bootstrap-grid.css';
import backIcon from '../../static/img/back.png';
import { withTranslation } from 'react-i18next';

class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div className='container'>
                {/* <div className='backButtonPrivacy' onClick={() => this.props.Back()}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} />Back</div> */}
                <div className='policy'>
                    <div>
                        <h1 className='policy__title'>{this.props.t('privacy_policy')}</h1>
                        <p className='policy__date'>{this.props.t('effective_date')}</p>
                    </div>
                    <div className='policy__rules'>
                        <div>
                            <h2 className='policy__rules_main-item'>1. {this.props.t('introduction')}</h2>
                            <p>{this.props.t('terms_introduction_desc')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>2. {this.props.t('policy_information_collect')}</h2>
                            <ul>
                                <li>{this.props.t('policy_information_collect_desc1')}</li>
                                <li>{this.props.t('policy_information_collect_desc2')}</li>
                                <li>{this.props.t('policy_information_collect_desc3')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2>3. {this.props.t('policy_information_use')}</h2>
                            <ul>
                                <li>{this.props.t('policy_information_use_desc1')}</li>
                                <li>{this.props.t('policy_information_use_desc2')}</li>
                                <li>{this.props.t('policy_information_use_desc3')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>4. {this.props.t('policy_information_disclosure')}</h2>
                            <ul>
                                <li>{this.props.t('policy_information_disclosure_desc1')}</li>
                                <li>{this.props.t('policy_information_disclosure_desc2')}</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>5. {this.props.t('policy_data_security')}</h2>
                            <p>{this.props.t('policy_data_security_desc1')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>{this.props.t('policy_your_rights')}</h2>
                            <p>{this.props.t('policy_your_rights_desc1')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>7. {this.props.t('policy_changes_to_policy')}</h2>
                            <p>{this.props.t('policy_changes_to_policy_desc1')}</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>8. {this.props.t('contact_us')}</h2>
                            <p>{this.props.t('contact_us_desc1')} <b>contact@what-money.miami</b></p>
                        </div>
                        <div>
                            <p>{this.props.t('policy_contact_us_desc2')}</p>
                            <p className='mt-4 mb-2'>{this.props.t('reg_address')}</p>
                            <div className='policy__info mb-4'>
                                <p>WhatMoney Inc.</p>
                                <p>1720 Harrison ST STE 18B</p>
                                <p>Hollywood, FL, 33020, US</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(PrivacyPolicyPage);
