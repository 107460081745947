export const currenciesList = [
  {
    label: "USD → USDT",
    input:"USD",
    output: "USDT"
  },
  {
    label: "USDT → USD",
    input: "USDT",
    output: "USD"
  },
  {
    label: "USD → USDC",
    input: "USD",
    output: "USDC"
  },
  {
    label: "USDC → USD",
    input: "USDC",
    output: "USD"
  },
];